import React from 'react'
import {Container, Box, Message, useColorMode, Card } from 'theme-ui'
import Section from '@components/Section'
import {VerticalTimeline }  from 'react-vertical-timeline-component'
import 'react-vertical-timeline-component/style.min.css'
import TimelineElement from '../TimelineElement'

const styles = {
    timelineSection: {
      padding: '10px 0',
      'div.soptimeline': {
        paddingTop: 0
      },
      '& #HiOutlineLightBulb': {
        ':hover': {
          'svg .svg-elem-1': {
              strokeDashoffset: '98.86273193359375px',
              strokeDasharray: '49.431365966796875px',
              '-webkit-transition': 'stroke-dashoffset 1s cubic-bezier(0.39, 0.575, 0.565, 1) 0s',
              transition: 'stroke-dashoffset 1s cubic-bezier(0.39, 0.575, 0.565, 1) 0s'
          },
          'svg.active .svg-elem-1': {
              strokeDashoffset: '148.29409790039062px'
          }
        }
      },
      '& #BsQuestion': {
        ':hover': {
          '& svg .svg-elem-1': {
              strokeDashoffset: '149.4269561767578px',
              strokeDasharray: '74.7134780883789px',
              '-webkit-transition': 'stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s',
                      transition: 'stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s'
          },
          '& svg.active .svg-elem-1': {
              strokeDashoffset: '74.7134780883789px'
          }
        }
      },
      '& #BsPeople': {
        ':hover': {
          '& svg .svg-elem-1': {
              strokeDashoffset: '874.48095703125px',
              strokeDasharray: '437.240478515625px',
              '-webkit-transition': 'stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s',
              transition: 'stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s',
            },
            
            '& svg.active .svg-elem-1': {
              strokeDashoffset: '437.240478515625px'
            },
            
            '& svg .svg-elem-2': {
              strokeDashoffset: '1369.8382568359375px',
              strokeDasharray: '684.9191284179688px',
              '-webkit-transition': 'stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s',
              transition: 'stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s'
            },
            
            '& svg.active .svg-elem-2': {
              strokeDashoffset: '684.9191284179688px',
            },
            
            '& svg .svg-elem-3': {
              strokeDashoffset: '703.424072265625px',
              strokeDasharray: '351.7120361328125px',
              '-webkit-transition': 'stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s',
                      transition: 'stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s'
            },
            
            '& svg.active .svg-elem-3': {
              strokeDashoffset: '351.7120361328125px'
            },
            
            '& svg .svg-elem-4': {
              strokeDashoffset: '684.3555908203125px',
              strokeDasharray: '342.17779541015625px',
              '-webkit-transition': 'stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s',
                      transition: 'stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s'
            },
            
            '& svg.active .svg-elem-4': {
              strokeDashoffset: '342.17779541015625px'
            }
          }
        },
        '& #BsPersonPlus': {
          ':hover': {
            '& svg .svg-elem-5': {
                strokeDashoffset: '1162.601318359375px',
                strokeDasharray: '581.3006591796875px',
                '-webkit-transition': 'stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s',
                transition: 'stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s'
            },
            
            '& svg.active .svg-elem-5': {
                strokeDashoffset: '581.3006591796875px'
            },
            
            '& svg .svg-elem-6': {
                strokeDashoffset: '1858.22705078125px',
                strokeDasharray: '929.113525390625px',
                '-webkit-transition': 'stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s',
                transition: 'stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s'
            },
            
            '& svg.active .svg-elem-6': {
                strokeDashoffset: '929.113525390625px'
            },
            
            '& svg .svg-elem-7': {
                strokeDashoffset: '452px',
                strokeDasharray: '226px',
                '-webkit-transition': 'stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s',
                transition: 'stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s'
            },
            
            '& svg.active .svg-elem-7': {
                strokedashoffset: '226px'
            }
          }
        },
        '& #BsArrowRepeat': {
          ':hover': {
            '& svg .svg-elem-1': {
                strokeDashoffset: '128.2315216064453px',
                strokeDasharray: '64.11576080322266px',
                '-webkit-transition': 'stroke-dashoffset 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0s',
                        transition: 'stroke-dashoffset 1.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0s'
            },
            '& svg.active .svg-elem-1': {
                strokeDashoffset: '64.11576080322266px'
            }
          }
        },
        '& #VscRocket': {
          ':hover': {
            '& svg .svg-elem-1': {
                strokeDashoffset: '3232.72216796875px',
                strokeDasharray: '1616.361083984375px',
                '-webkit-transition': 'stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s',
                        transition: 'stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s'
            },
            '& svg.active .svg-elem-1': {
                strokeDashoffset: '1616.361083984375px'
            },
            '& svg .svg-elem-2': {
                strokeDashoffset: '470.1169738769531px',
                strokeDasharray: '235.05848693847656px',
                '-webkit-transition': 'stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s',
                        transition: 'stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s'
            },
            '& svg.active .svg-elem-2': {
                strokeDashoffset: '235.05848693847656px'
            }
          }
        }
    },
    timeline: {
        '::before': {

        }
    },
    timelineDark: {

    }
}

const SOPTimeline = ({}) => {
    const [colorMode] = useColorMode()
    const isDark = colorMode === `dark`

    return (
      <Box sx={styles.timelineSection}>
        <VerticalTimeline className='soptimeline' lineColor={ isDark ? '#fff' : 'var(--theme-ui-colors-omegaDark,#718096)'}>
            <TimelineElement
            date="Get started Today"
            iconGraphic='HiOutlineLightBulb'
            title='It all starts with an idea.'
            subtitle="Let's help shape the best idea to meet your needs."
            content="You have a clear idea of what you want. You are still not sure? no worries; take advantage of our free consultation with an expert who can provide consultation on what is best to realize your ideas."
          />
          <TimelineElement
            date="Evaluation"
            iconGraphic='BsQuestion'
            title="Do we have the resources."
            subtitle="Your project should be implemented to standard."
            content="We hold ourselves up to very high standards. We evaluate our available resources in relation to your project and tell you if we can work on your project and deliver with top-notch quality. If we can not deliver your project according to those standards, then we will discuss with you the next options and best next steps to realize your idea with the best possible outcome."
          />
          <TimelineElement
            date="Kick off"
            iconGraphic='BsPeople'
            title="Dedicated Resources"
            subtitle="We pull together resources for your project."
            content="If we think we can deliver your project according to the standards, we will pull together a team of highly skilled developers, designers, Quality Assurance, etc. to work on the project."
          />
          <TimelineElement
            date="Responsible"
            iconGraphic='BsPersonPlus'
            title="Dedicated Contact Person"
            content="As a partner, we keep you in the loop throughout the project. You will be in contact with a specialist on site in Germany who will keep you up to date throughout the project."
            subtitle="We assume all responsibility to deliver, and assign you a dedicated project manager."
          />
          <TimelineElement
            date="Stay in the loop"
            iconGraphic='BsArrowRepeat'
            title="Transparency and Agile Development"
            content="In the true spirit of a transparent and agile process we like to keep you updated and get quick feedback as the project progresses, so we will organize frequent feedback sessions with you."
            subtitle="Continous update on project status."
          />
          <TimelineElement
            date="Delivered"
            iconGraphic='VscRocket'
            title="Delivery and Support"
            subtitle="A product that meets your every needs."
            content="In the end, we deliver a top-notch solution to meet your needs; but this is not the end of it, we like to continually support our partners with the maintenance and continual growth of their business or organization whenever possible."
          />
          <TimelineElement
              iconGraphic='BsCheck2'
          />
        </VerticalTimeline>
      </Box>
    )
}

export default SOPTimeline