import React from 'react'
import {Message, useColorMode } from 'theme-ui'

const styles = {
    blockquoteDark: {
        backgroundColor: '#4a5568',
        color: '#fff'
    }
}

export default ({quote}) => {
    const [colorMode] = useColorMode()
    const isDark = colorMode === `dark`

    return (
        <Message sx={isDark ? styles.blockquoteDark : {}}>
            {quote}
        </Message>
    )
}
