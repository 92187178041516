import React from 'react'
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next'
import {Heading, Box, Container, Message, useColorMode } from 'theme-ui'
import { Layout, Stack, Main, Sidebar, Hero } from '@layout'
import CardList from '@components/CardList'
import Divider from '@components/Divider'
import Section from '@components/Section'
import MemphisPattern from '@components/MemphisPattern'
import Seo from '@widgets/Seo'
import NewsletterExpanded from '@widgets/NewsletterExpanded'
import Sponsor from '@widgets/Sponsor'
import Categories from '@widgets/Categories'
import { useBlogCategories } from '@helpers-blog'
import {PartnerLogo} from '../../flow-ui-components/PartnerLogo/PartnerLogo.jsx'
import BlockQuote from '../../flow-ui-components/BlockQuote/BlockQuote'
import SOPTimeline from '../../flow-ui-components/SOPTimeline'
import MemphisPattern2 from '../../flow-ui-components/MemphisPattern2'
import ServicesList from '../../flow-ui-components/ServicesList'



const styles = {
  heroThumbsContainer: {
    left: `50%`,
    top: `50%`,
    position: `absolute`,
    transform: `translate(-50%, -50%)`,
    display: [`none`, null, null, `block`]
  },
  heroThumbsInner: {
    width: `1/3`,
    ml: `auto`
  },
  heading: {
    background: t => `
      linear-gradient(
        45deg,
        ${t.colors.alpha} 32.5%,
        ${t.colors.betaDark} 50.5%)
    `,
    color: `beta`,
    WebkitBackgroundClip: `text`,
    WebkitTextFillColor: `transparent`,
    fontWeight: `bold`
  },
  running: {
    color: `omegaDark`,
    fontWeight: `normal`,
    fontSize: '1.5rem',
    maxWidth: [`full`, null, `3/3`]
  },
  blockquoteDark: {
    backgroundColor: '#e2e8f0',
    color: '#fff'
  }
}

const Posts = ({
  data: { posts = {}, featuredPosts = {}, recentPosts = {} },
  ...props
}) => {
  const { pageContext: { services = {} } = {} } = props
  const categories = useBlogCategories()
  const sliderRef = React.useRef()
  const { t } = useTranslation()
  var featuredPostsAdvantages = []
  var featuredPostsDivision = []


  featuredPosts.nodes.map((post, index) => { 
    if(post.category.name == "Your Advantage with Us")
      featuredPostsAdvantages.push(post)
    else if(post.category.name == "Division")
      featuredPostsDivision.push(post)
  })

  return (
    <Layout {...props}>
      <Seo title='Home' />
      <Hero full sx={{ position: `relative` }}>
        <CardList
          nodes={featuredPostsDivision}
          limit={3}
          variant='horizontal-cover-hero'
          omitFooter
          slider
          autoPlay
          fade
          dots={false}
          arrows={false}
          ref={sliderRef}
          loading='eager'
        />
        <Container sx={styles.heroThumbsContainer}>
          <Box sx={styles.heroThumbsInner}>
            <CardList
              nodes={featuredPostsDivision}
              limit={3}
              variant='horizontal-aside'
              imageVariant='hero'
              omitCategory
              asNavFor={sliderRef}
              omitFooter={true}
              loading='eager'
            />
          </Box>
        </Container>
      </Hero>
      <PartnerLogo />
      <Divider />

      <Hero wide sx={{ pb: [3, 5], pt: [4, 5] }}>
      <Box sx={{ position: `relative`, zIndex: 2 }}>
      <ServicesList />
      </Box>
      </Hero>

      <Divider/>
      <Hero wide sx={{ bg: `contentBg`, pb: [3, 5], pt: [4, 5] }}>
        <Box sx={{ position: `relative`, zIndex: 2 }}>
          <CardList
            nodes={featuredPostsAdvantages}
            limit={4}
            skip={0}
            link = "#"
            columns={[1, 2, 2, 4]}
            variant={['vertical-cover-no-metadata']}
            omitCategory
            title={t('Seeking a reliable partner for executing IT projects? - You have these Advantages with US.')}
            omitFooter={true}
            aside
          />
        </Box>
      </Hero>


      <Hero wide sx={{ bg: `contentBg`}}>
        
        <Divider space={3} />
        <Heading variant='h2' sx={styles.running}>
          <Trans>There is an ever increasing need for digitization and automation, the constraints for automation and digitization projects is ever increasing. The effective implementation of reliable systems is ever challenging. We partner with our clients to design and develop secure, reliable and user-friendly digital solutions.</Trans>
        </Heading>
        <Divider space={4} />
        <Section title={t('Our Commitment')} aside={t('What we stand for!')}>
          <Heading variant='h2' sx={styles.running}>
            <Trans>We believe systems that are a critical component of any organization or business should be implemented with the highest quality without breaking the wallet in the process. With our expertise, extensive experience, and broad network of professionals, we understand this more than any other, and we commit ourselves to diligently work with our clients to develop the best solutions.</Trans>
          </Heading>
            <BlockQuote quote = {t("For many years many companies and governments tried to make our species interplanetary but failed, the company which is now leading the race to Mars, and successfully so, is achieving that with only a fraction of the budget of previously failed attempts. Success and quality doesn't always have to come at astronomical costs, all that is needed is the correct expertise, the right team, and a proven project management process. This is our commitment to our partners.")}/>
            <Divider space={2} />
        </Section>
        <Divider />
        <MemphisPattern />
      </Hero>


      <Divider />
      <Hero wide sx={{ bg: `contentBg`}}>
        <Divider />
        <Section title={t('How it works')} aside={t('What we stand for!')}>
          <Divider />
          <SOPTimeline />
          <Divider />
        </Section>
        <Divider />
        <MemphisPattern2 />
      </Hero>

      <Divider />
      {posts.group.length &&
        posts.group.map((group, index) => (
          <React.Fragment key={`${group.categoryName}.list`}>
            {index === 1 && (
              <>
                <Divider />
                <Hero wide sx={{ pb: [3, 5], pt: [4, 5] }}>
                  <Box sx={{ position: `relative`, zIndex: 2 }}>
                    <CardList
                      nodes={featuredPostsDivision}
                      limit={2}
                      skip={0}
                      columns={[1, 1, 1, 2]}
                      variant={[
                        'horizontal-md',
                        'horizontal',
                        'horizontal',
                        'horizontal-lg'
                      ]}
                      title={t('Experts at the center of advancements.')}
                      omitFooter={true}
                      aside
                    />
                  </Box>
                  <MemphisPattern />
                </Hero>
              </>
            )}
            {index !== posts.group.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      <Stack>
        <Main>
          {services.mailchimp && (
            <>
              <Divider />
              <NewsletterExpanded simple />
            </>
          )}
        </Main>
      </Stack>
    </Layout>
  )
}

export default Posts
